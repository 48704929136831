@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

:root {
  --yellow: #f9d806;
  --light-yellow: #ffee80;
  --black: #130f40;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

html {
  overflow-x: hidden;
  scroll-padding-top: 7rem;
  scroll-behavior: smooth;
}

section {
  padding: 2rem 9%;
}

.heading {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 4.5rem;
  color: var(--black);
}

.heading span {
  position: relative;
  z-index: 0;
}

.heading span::before {
  content: "";
  position: absolute;
  bottom: 1rem;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--light-yellow);
  z-index: -1;
  clip-path: polygon(0 90%, 100% 80%, 100% 100%, 0% 100%);
}

.btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.8rem 3rem;
  background: var(--light-yellow);
  color: var(--black);
  cursor: pointer;
  font-size: 1.7rem;
  border-radius: 0.5rem;
  font-weight: 500;
  text-align: center;
}

.btn:hover {
  background: var(--yellow);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3rem 9%;
  z-index: 1000;
  background: #fff;
}

.header .navbar a {
  margin: 0 1rem;
  font-size: 1.7rem;
  color: var(--black);
}

.header .navbar a:hover {
  color: var(--yellow);
}

#login-btn .btn {
  margin-top: 0;
}

#login-btn i {
  display: none;
  font-size: 2.5rem;
  color: var(--light-color);
}

.header.active {
  padding: 2rem 9%;
  box-shadow: var(--box-shadow);
}

#menu-btn {
  font-size: 2.5rem;
  color: var(--light-color);
  display: none;
}

.home {
  padding-top: 10rem;
  text-align: left;
}

.home h3 {
  color: var(--black);
  font-size: 5.5vw;
  text-transform: uppercase;
}

.home h4 {
  font-size: 2.0vw;
}

.home img {
  width: 100%;
  margin: 1rem 0;
}

.contact .row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.contact .row .map {
  flex: 1 1 42rem;
  width: 100%;
  padding: 2rem;
  box-shadow: var(--box-shadow);
  border: var(--border);
  border-radius: 0.5rem;
}

.contact .row form {
  padding: 2rem;
  flex: 1 1 42rem;
  box-shadow: var(--box-shadow);
  border: var(--border);
  text-align: center;
  border-radius: 0.5rem;
}

.contact .row form h3 {
  font-size: 3rem;
  color: var(--black);
  padding-bottom: 1rem;
}

.contact .row form .box {
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem 1.2rem;
  font-size: 1.6rem;
  text-transform: none;
  border: var(--border);
  margin: 0.7rem 0;
}

.contact .row form textarea {
  height: 12rem;
  resize: none;
}

.btn-red {
  background-color: #ea3f3f; /* Change this to your desired red color */
  color: white; /* Text color on the button */
  padding: 15px 30px; /* Adjust padding as needed */
  text-decoration: none;
  font-size: 18px; /* Adjust font size as needed */
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Style for button hover effect (optional) */
.btn-red:hover {
  background-color: #df1f1f; /* Change this to the hover color you prefer */
}

.our-services{
  padding-top: 20px;
}
